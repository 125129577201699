<template>
  <div>
    <div class="row">
      <div class="col-4 offset-4 text-center">
        <img src="@/assets/cps.svg" alt="" />
        DISC PERSONALITY TEST
      </div>
    </div>
    <div class="row" v-if="!submitted">
      <!-- <div class="col-12 col-lg-6 form-group">
        <label>First Name</label>
        <input
          type="text"
          required
          name="first"
          class="form-control"
          v-model="contact.first"
        />
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label>Last Name</label>
        <input
          type="text"
          required
          name="last"
          class="form-control"
          v-model="contact.last"
        />
      </div>
      <div class="col-12 col-lg-6 form-group">
        <label>Email</label>
        <input
          type="email"
          required
          name="email"
          class="form-control"
          v-model="contact.email"
        />
      </div> -->
      <div class="col-12 form-group">
        <label>Gender</label>
        <select name="gender" class="form-control" v-model="contact.gender">
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
      </div>
    </div>
    <div class="alert alert-info" v-if="!submitted">
      READ CAREFULLY: Below there are 24 four-word groups: Select two words in
      each group-<u>ONE</u> word which is MOST like you and <u>ONE</u> word
      which is LEAST like you.
    </div>
    <div class="alert alert-info" v-if="loading">Loading Questions</div>
    <div
      class="alert alert-success text-center  mt-4"
      v-if="submitted && contact.personality"
    >
      <h3>
        Thank you for completing the DISC Personality test. <br />
        <br />Your Personality is:
      </h3>
      <h2>{{ contact.personality }}</h2>
    </div>
    <div class="row small" v-if="!loading && !submitted && questions">
      <div
        class="col-lg-4 col-md-6 col-12"
        v-for="(question, k) in questions"
        :key="k"
      >
        <div class="border p-3 my-3">
          <div class="row">
            <div class="col-6">&nbsp;</div>
            <div class="col-3 text-center text-uppercase font-weight-bold">
              Most
            </div>
            <div class="col-3 text-center text-uppercase font-weight-bold">
              Least
            </div>
          </div>
          <div class="row" v-for="(disc, label) in question" :key="disc">
            <div class="col-6">{{ label }}</div>
            <div class="col-3 text-center">
              <input
                type="radio"
                :name="`Q-${k}-M`"
                :value="disc"
                v-if="answers[k]"
                v-model="answers[k].most"
                :disabled="answers[k].least === disc"
                @click="selectAnswer(k, disc, 'M')"
              />
            </div>
            <div class="col-3 text-center">
              <input
                type="radio"
                :name="`Q-${k}-L`"
                :value="disc"
                v-if="answers[k]"
                v-model="answers[k].least"
                :disabled="answers[k].most === disc"
                @click="selectAnswer(k, disc, 'L')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn btn-success btn-lg btn-block mb-4"
      v-if="!loading && !submitted"
      :disabled="saving"
      @click="submitTest()"
    >
      Submit Test
    </button>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { Options, Vue } from "vue-class-component";
import { Question, Answers, Contact, Totals, TestData } from "@/types";

@Options({})
export default class Home extends Vue {
  loading = true;
  saving = false;
  submitted = false;
  questions: Question[] = [];
  answers: Answers = {};
  contact: Contact = {
    first: "Public",
    last: "Test",
    email: "test@test.com",
    gender: "",
    personality: ""
  };
  store = useStore();

  async mounted() {
    await this.store.dispatch("getQuestions");
    this.questions = await this.store.state.questions;
    this.loading = await this.store.state.loading;
    this.saving = await this.store.state.saving;

    if (this.questions) {
      Object.keys(this.questions).filter(k => {
        this.answers[k] = { question: k, most: null, least: null };
      });
      if (localStorage.getItem("dt-an")) {
        const existingAns: Answers = JSON.parse(
          localStorage.getItem("dt-an") as string
        );
        this.answers = existingAns;
      }
    }
  }

  submitTest() {
    if (this.contact.gender == "") {
      alert("You must select your gender");
      return;
    }

    let mosts = 0;
    let leasts = 0;
    Object.values(this.answers).filter(a => {
      if (a.most) {
        mosts++;
      }
      if (a.least) {
        leasts++;
      }
    });

    const totals: Totals = {
      leasts: leasts,
      mosts: mosts,
      questions: Object.keys(this.questions).length
    };

    if (
      totals.questions > totals.mosts ||
      totals.questions > totals.leasts ||
      totals.leasts != totals.mosts
    ) {
      alert("The Test is incomplete, you may have missed some questions");
      return;
    }

    const data: TestData = {
      contact: this.contact,
      answers: this.answers
    };
    this.store
      .dispatch("submitTest", data)
      .then(res => {
        localStorage.removeItem("dt-an");
        this.submitted = true;
        this.contact.personality = res.data.personality;
      })
      .catch(err => {
        let msg = "";
        Object.values(err.response.data).forEach(err => {
          msg += err + "\n";
        });
        alert("error: " + msg);
      });
  }

  selectAnswer(question: number, value: string, type: string) {
    if (type === "M") {
      this.answers[question].most = value;
    }
    if (type === "L") {
      this.answers[question].least = value;
    }

    localStorage.setItem("dt-an", JSON.stringify(this.answers));
    return;
  }
}
</script>
