
import { useStore } from "vuex";
import { Options, Vue } from "vue-class-component";
import { Question, Answers, Contact, Totals, TestData } from "@/types";

@Options({})
export default class Home extends Vue {
  loading = true;
  saving = false;
  submitted = false;
  questions: Question[] = [];
  answers: Answers = {};
  contact: Contact = {
    first: "Public",
    last: "Test",
    email: "test@test.com",
    gender: "",
    personality: ""
  };
  store = useStore();

  async mounted() {
    await this.store.dispatch("getQuestions");
    this.questions = await this.store.state.questions;
    this.loading = await this.store.state.loading;
    this.saving = await this.store.state.saving;

    if (this.questions) {
      Object.keys(this.questions).filter(k => {
        this.answers[k] = { question: k, most: null, least: null };
      });
      if (localStorage.getItem("dt-an")) {
        const existingAns: Answers = JSON.parse(
          localStorage.getItem("dt-an") as string
        );
        this.answers = existingAns;
      }
    }
  }

  submitTest() {
    if (this.contact.gender == "") {
      alert("You must select your gender");
      return;
    }

    let mosts = 0;
    let leasts = 0;
    Object.values(this.answers).filter(a => {
      if (a.most) {
        mosts++;
      }
      if (a.least) {
        leasts++;
      }
    });

    const totals: Totals = {
      leasts: leasts,
      mosts: mosts,
      questions: Object.keys(this.questions).length
    };

    if (
      totals.questions > totals.mosts ||
      totals.questions > totals.leasts ||
      totals.leasts != totals.mosts
    ) {
      alert("The Test is incomplete, you may have missed some questions");
      return;
    }

    const data: TestData = {
      contact: this.contact,
      answers: this.answers
    };
    this.store
      .dispatch("submitTest", data)
      .then(res => {
        localStorage.removeItem("dt-an");
        this.submitted = true;
        this.contact.personality = res.data.personality;
      })
      .catch(err => {
        let msg = "";
        Object.values(err.response.data).forEach(err => {
          msg += err + "\n";
        });
        alert("error: " + msg);
      });
  }

  selectAnswer(question: number, value: string, type: string) {
    if (type === "M") {
      this.answers[question].most = value;
    }
    if (type === "L") {
      this.answers[question].least = value;
    }

    localStorage.setItem("dt-an", JSON.stringify(this.answers));
    return;
  }
}
