import { Question } from "@/types";
import { createStore } from "vuex";

import axios from "axios";

type State = {
  loading: boolean;
  saving: boolean;
  questions: Question[];
};

const state: State = {
  questions: [],
  loading: false,
  saving: false
};

export default createStore({
  state: state,
  mutations: {
    SET_QUESTIONS: function(state, payload) {
      state.questions = payload;
    },
    SET_LOADING: function(state, payload) {
      state.loading = payload;
    },
    SET_SAVING: function(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    getQuestions: async function({ commit }) {
      commit("SET_LOADING", true);
      const res = await axios.get(
        "https://coaching.craigproctor.com/disc-test/questions",
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const data = await res.data;

      commit("SET_QUESTIONS", data);
      commit("SET_LOADING", false);
    },
    submitTest: function({ commit }, body) {
      commit("SET_SAVING", true);

      return axios
        .post("https://coaching.craigproctor.com/disc-test/submit", body, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          commit("SET_SAVING", false);
          return res;
        });
    }
  },
  modules: {}
});
