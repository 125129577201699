<template>
  <div class="container mt-4">
    <router-view />
  </div>
</template>

<style lang="scss">
input[type="radio"] {
  cursor: pointer;
}

@import "~bootstrap/scss/bootstrap.scss";
</style>
